import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { checkUsername } from '../../helper/common';
import { error, warning } from '../../utils/console';
import { useCacheService } from '../../hook/useCacheService';
import { useDataService } from '../../hook/useDataService';
import { useSocket } from '../../hook/useSocket';
import { editStatus } from '../../redux/state/status';
import axios from 'axios';
import forge from 'node-forge';
import { useTranslation } from 'react-i18next';
import { SendIntentEventInterface } from '../../utils/interfaces';
import { getOS } from '../../utils/navigator';
import StagingConfig from '../../config/staging.config.json';
import ProductionConfig from '../../config/production.config.json';

interface Props {
    height: number;
}
var config: any;
var HashScriptLink = '';

const LoginModal = (props: Props) => {
    const socket = useSocket();

    const { t } = useTranslation();

    const isLoginModal = useSelector(
        (state: { status: { isLoginModal: Boolean } }) =>
            state.status.isLoginModal
    );

    const cacheService = useCacheService();

    const dataService = useDataService();

    const [loginUsername, setLoginUsername] = useState('');

    const [loginPassword, setLoginPassword] = useState('');

    const usernamePlaceholder = t('loginUsername');

    const passwordPlaceholder = t('loginPassword');

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLoginUsername(event.target.value);
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLoginPassword(event.target.value);
    };

    let dispatch = useDispatch();

    const closeLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isLoginModal',
                value: false,
            })
        );
    };

    const openLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: true,
            })
        );
    };

    const closeLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: false,
            })
        );
    };

    const openSuccessLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isSuccessLoginModal',
                value: true,
            })
        );
    };

    const openFailLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isFailLoginModal',
                value: true,
            })
        );
    };

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const acctId = useSelector(
        (state: { session: { acctId: string } }) => state.session.acctId
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const dbProfile = useSelector(
        (state: { session: { dbProfile: string } }) => state.session.dbProfile
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const intentId = useSelector(
        (state: { status: { intentId: string } }) => state.status.intentId
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );
    
    const botConfig = () => {
        if (dbProfile === 'mario.production') {
            return ProductionConfig.find(config => config.botId === parseInt(botId) && config.accountId === parseInt(acctId));
        } else {
            return StagingConfig.find(config => config.botId === parseInt(botId) && config.accountId === parseInt(acctId));
        }
    }

    // const dynamicScript = (callback: any) => {
    //     const existingScript = document.getElementById('dynamicjs');
    //     if (!existingScript) {
    //         const script = document.createElement('script');
    //         script.src = HashscriptUrl;
    //         script.id = 'dynamicjs';
    //         document.body.appendChild(script);
    //         script.onload = () => {
    //             console.log('script loaded!!!');
    //             callback()
    //         }
    //     }
    // };
    // useEffect(() => {
    //     dynamicScript(() => {
    //         window.encryptValue(loginPassword)
    //     });
    // }, []);

    const ProcessLogin = () => {
        openLoadingModal();
        closeLoginModal();
        const Params = {
            access_token: botConfig()?.access_token,
            session_id: chatId,
        };
        axios.post(`${botConfig()?.boPath}/api/initProcess`, Params).then((res) => {
            const data = res.data;
            const ScriptName = data.script_name;
            const RefreshToken = data.refresh_token;
            const ScriptUrl = `${botConfig()?.boPath}/api/script/${ScriptName}`;
            axios.post(ScriptUrl, Params).then((res) => {
                const data = res.data;
            });
            const LoginJson = {
                date_time_format: 'yy/mm/dd hh:MM:ss',
                password: loginPassword,
                refresh_token: RefreshToken,
                script_name: ScriptName,
                user_name: loginUsername,
            };
            const HashscriptUrl = `${botConfig()?.boPath}/api/hashScript/${ScriptName}`
            axios.post(HashscriptUrl, LoginJson).then(function (response) {
                const HashscriptText = response.data;
                HashScriptLink = `${botConfig()?.boPath}/api/getHashscript/${ScriptName}`
                const existingScript = document.getElementById('dynamicjs');
                // console.log(existingScript);
                const doEncryption = () => {
                    const CryptoJS = require('crypto-js');
                    const getEncryptValue = window.encryptValue(
                        LoginJson.password,
                        forge,
                        CryptoJS
                    );
                    getEncryptValue.then((result: any) => {
                        const securePasswordHash = result;
                        console.log(
                            'Encrypted Password: ',
                            securePasswordHash
                        );
                        const submitLoginUrl = `${botConfig()?.boPath}/api/submitPasswordHash`;
                        const submitLoginJson = {
                            acct_id: acctId.toString(),
                            bot_id: botId.toString(),
                            customer_language_culture: 'zh-cn',
                            refresh_token: RefreshToken,
                            secured_password_hash: securePasswordHash,
                            session_id: chatId,
                            user_name: loginUsername,
                        };
                        console.log('submitLoginJson:', submitLoginJson, 'url:', `${botConfig()?.boPath}/api/submitPasswordHash`);
                        axios
                            .post(submitLoginUrl, submitLoginJson)
                            .then(function (response) {
                                const loginResponse = response.data;
                                console.log("RESPONSE IS HERE",response);
                                closeLoadingModal();
                                if (loginResponse.login_status) {
                                    // openSuccessLoginModal();
                                    console.log('SUCCESS LOGIN');
                                    let data = {
                                        username: username,
                                        chatId: chatId,
                                        type: 'goToIntent',
                                        intentId: intentId,
                                        triggerTime: new Date().toJSON(),
                                        platform: 'mario',
                                        group_name: '',
                                        customer_lang_culture:
                                            botLanguage.toLowerCase() ===
                                            'chinese'
                                                ? 'zh-cn'
                                                : botLanguage.toLowerCase() ===
                                                  'thai'
                                                ? 'th-th'
                                                : botLanguage.toLowerCase() ===
                                                  'vietnamese'
                                                ? 'vi-vn'
                                                : botLanguage.toLowerCase() ===
                                                  'vietnam'
                                                ? 'vi-vn'
                                                : botLanguage.toLowerCase() ===
                                                  'english'
                                                ? 'en'
                                                : botLanguage.toLowerCase(),
                                        operating_system: getOS(navigator),
                                        screen_resolution:
                                            window.screen.width +
                                            '*' +
                                            window.screen.height,
                                        processor: processor,
                                    } as SendIntentEventInterface;
                                    socket.sendIntentEvent(data);
                                } else {
                                    openFailLoginModal();
                                    console.log('LOGIN FAILED');
                                }
                            });
                    });
                };
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.src = HashScriptLink;
                    script.id = 'dynamicjs';
                    document.body.appendChild(script);
                    script.onload = () => {
                        console.log('script loaded!!!');
                        doEncryption();
                    };
                } else {
                    doEncryption();
                }
            });
        });
    };

    return (
        <div
            style={{ height: props.height }}
            className={classNames(
                'fixed w-screen h-screen bg-zinc-800/90 flex justify-center items-center z-20'
            )}
        >
            <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
                <div className="flex justify-between items-start p-4 rounded-t border-b border-gray-300">
                    <h3 className="text-xl text-black">{t('loginTitle')}</h3>
                    <button
                        onClick={closeLoginModal}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-700 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="defaultModal"
                    >
                        <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div className="content text-center text-sm small:text-base medium:text-lg">
                    <input
                        // onKeyDown={handleKeyDown}
                        onChange={handleUsernameChange}
                        className={classNames(
                            'mb-2 mt-6 bg-slate-200 text-center px-4 py-2 w-80pc rounded-lg text-slate-800 focus:outline-none shadow-lg'
                        )}
                        placeholder={usernamePlaceholder}
                        value={loginUsername}
                        type="text"
                    />
                    <input
                        // onKeyDown={handleKeyDown}
                        onChange={handlePasswordChange}
                        className={classNames(
                            'mb-4 mt-2 bg-slate-200 text-center px-4 py-2 w-80pc rounded-lg text-slate-800 focus:outline-none shadow-lg'
                        )}
                        placeholder={passwordPlaceholder}
                        value={loginPassword}
                        type="password"
                    />
                </div>
                <div>
                    <button
                        style={{
                            backgroundColor: botButtonColor
                                ? botButtonColor.slice(0, 7)
                                : '',
                        }}
                        onClick={ProcessLogin}
                        type="button"
                        className={classNames(
                            'flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-2 text-white font-bold shadow-lg',
                            {
                                'bg-sky-500': !botButtonColor,
                            }
                        )}
                    >
                        {t('submit')}
                    </button>
                </div>
            </div>
        </div>
    );
};

LoginModal.propTypes = {};

export default LoginModal;
